<template>
  <div class="d-flex main-container">
    <!-- header -->
    <v-col
      cols="5"
      class="pa-0">
      <CurvedHeader title="HI AGAIN" />
    </v-col>
    <!-- create account question -->
    <v-col
      cols="4"
      class="px-7 mx-auto"
      align-self="center">
      <!-- login form -->
      <div class="create-account-title mt-4 mb-3 brandGrey2--text text-center">Log in</div>
      <div class="max-width mx-auto text-center px-5">
        <v-text-field
          color="primaryColor"
          class="mb-3"
          type="email"
          v-model="email"
          @blur="cleanEmail">
          <template slot="label"> Enter your <strong>work email address</strong> </template>
        </v-text-field>
        <v-text-field
          label="Enter your password"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
          hint="At least 8 characters"
          color="primaryColor"
          v-model="password">
          <template v-slot:append>
            <v-img
              height="40"
              width="40"
              :src="showPassword ? eye : eyeOff" />
          </template>
        </v-text-field>
        <v-btn
          text
          color="brandGrey2"
          class="float-left px-0 mx-0 mb-3 px-3 forgot-password text-capitalize"
          @click="$router.push({ name: 'ForgotPassword' })">
          Forgot password
        </v-btn>
        <v-btn
          class="submit py-7"
          width="80%"
          large
          color="primaryColor"
          rounded
          dark
          depressed
          :loading="isLoading"
          @click="signIn()">
          Log in
        </v-btn>

        <div class="terms-and-conditions my-8">
          By continuing, you agree to our
          <span
            class="font-weight-medium"
            @click="openTermsAndConditions"
            >Terms and Conditions</span
          >
        </div>
        <div class="receive-invite-wrapper px-2">
          <div class="received-invite">
            <div class="received-invite-title mb-2">Received an invite?</div>
            <div class="received-invite-description">
              Please click the link you received to sign up with your organisation.
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
  import LoginMixin from '@/views/auth/login/Login.mixin.vue';
  export default {
    mixins: [LoginMixin],
  };
</script>
<style lang="css" scoped>
  .main-container {
    height: 100%;
  }
  .already-have-account {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    flex: none;
    justify-content: center;
    align-items: center;
  }
  .create-account-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  .desktop-alert h3 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.16rem;
  }
  .desktop-alert p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.68px;
  }
  .max-width {
    max-width: 400px;
  }
  .v-text-field {
    border-bottom-color: rgba(184, 0, 0, 0.986);
  }
  .terms-and-conditions {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--v-brandGrey2-base);
  }
  .forgot-password {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--v-brandGrey2-base);
  }
  .received-invite {
    text-align: left;
    background: var(--v-offWhite-base);
    border-radius: 16px;
    padding: 16px;
  }
  .received-invite-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: var(--v-primaryColor-base);
  }
  .received-invite-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: var(--v-brandGrey1-base);
  }
  .submit {
    background-image: linear-gradient(to right, var(--v-primaryColor-base), var(--v-secondaryColor-base));
  }
  .v-label.theme--light {
    color: var(--v-brandGrey2-base) !important;
    top: -10px !important;
  }
  .theme--light.v-label.v-label--active {
    margin-bottom: 40px;
  }
  .v-label {
    top: -10px !important;
  }
  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(51, 51, 51, 0.161) !important;
  }
</style>

<style>
  .v-alert__content {
    display: flex !important;
    align-items: center !important;
  }
</style>
