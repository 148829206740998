<script>
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import { mapActions, mapMutations } from 'vuex';
  export default {
    name: 'Login',
    components: {
      CurvedHeader,
    },
    data() {
      return {
        email: null,
        password: null,
        showPassword: false,
        isLoading: false,
      };
    },
    computed: {
      eye() {
        return require('@/assets/auth/eye.png');
      },
      eyeOff() {
        return require('@/assets/auth/eye-off.png');
      },
    },
    mounted() {
      const eyesIcon = document.getElementsByClassName('v-input__append-inner')[0];
      eyesIcon?.addEventListener('click', this.toggleShowPassword);
    },
    beforeDestroy() {
      const eyesIcon = document.getElementsByClassName('v-input__append-inner')[0];
      eyesIcon?.removeEventListener('click', this.toggleShowPassword);
    },
    methods: {
      ...mapActions('AuthModule', ['signInToFirebaseByEmail']),
      ...mapMutations(['setErrorSnackbarMessage']),
      toggleShowPassword() {
        this.showPassword = !this.showPassword;
      },
      openTermsAndConditions() {
        window.location.href = 'https://weareluminate.co/app-terms-conditions/';
      },
      async signIn() {
        this.isLoading = true;
        try {
          await this.signInToFirebaseByEmail({
            email: this.email,
            password: this.password,
          });
          this.$router.push({ name: 'Home' });
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoading = false;
        }
      },
      cleanEmail() {
        this.email = this.email.trim();
      },
    },
  };
</script>
